@mixin input-like-text-field {
  padding: 0;
  height: auto;
  border: none;
  background-color: transparent;
  border-radius: 0;
  line-height: 1;

  &:active, &:focus, &:focus-visible {
    box-shadow: none;
    outline: none;
    border: none;
  }
}

@mixin status-style ($padding, $min-width, $border-radius){
  width: fit-content;
  padding: $padding;
  border-radius: $border-radius !important;
  text-align: center;
  height: auto;
  border: 1px solid;
}

@mixin status-color($borderColor, $backgroundColor, $color) {
   color: $color !important;
   background-color: $backgroundColor !important;
   border-color: $borderColor !important;
}
