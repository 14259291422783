.formio-component-panel{
    >.card{
        border: none !important;
        background: transparent;
        .card-header{
            cursor: auto;
            padding: 39px 0 26px;
            background: transparent;
        }

        @media (max-width: 576px) {
            .card-header {
                padding: 0 0 26px;
            }
        }
    }

    .card-title{
        font-weight: bold;
        font-size: 21px;
        line-height: 135%;
        margin-bottom: 26px;
    }
}
