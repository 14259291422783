@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFPro/SFProText-Bold.woff2') format('woff2'),
        url('../assets/fonts/SFPro/SFProText-Bold.woff') format('woff'),
        url('../assets/fonts/SFPro/SFProText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFPro/SFProText-Semibold.woff2') format('woff2'),
        url('../assets/fonts/SFPro/SFProText-Semibold.woff') format('woff'),
        url('../assets/fonts/SFPro/SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFPro/SFProText-Regular.woff2') format('woff2'),
        url('../assets/fonts/SFPro/SFProText-Regular.woff') format('woff'),
        url('../assets/fonts/SFPro/SFProText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFPro/SFProText-Medium.woff2') format('woff2'),
        url('../assets/fonts/SFPro/SFProText-Medium.woff') format('woff'),
        url('../assets/fonts/SFPro/SFProText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/SFPro/SFProText-Heavy.woff2') format('woff2'),
        url('../assets/fonts/SFPro/SFProText-Heavy.woff') format('woff'),
        url('../assets/fonts/SFPro/SFProText-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

*, body{
    font-family: var(--app-font);
    font-size: var(--app-font-size);
    color: var(--form-text-color);
}