
.helper_well-content-horizontal,
.well_content-horizontal {
    > .card {
        > div {
            display: flex;
            > * + * {
                margin-left: 5px;
            }

            > * + .input-as-status{
                margin-left: 25px;
                display: flex;
                align-items: center;
            }

            @media (max-width: 576px) {
                display: block;

                > * + * {
                    margin-left: 0;
                }

                > * + .input-as-status{
                    margin-left: 0;
                }
            }
        }


    }

    &.well_justify-content-end {
        > .card {
            > div {
                justify-content: flex-end;
            }
        }
    }

    &.well_justify-content-center {
        > .card {
            > div {
                justify-content: center;
            }
        }
    }

    .input-as-status{
       transform: translateY(3px);
    }
}

.well_content-horizontal-with-space{
    > .card {
        > div {
            display: flex;
            > * + * {
                margin-left: 5px;
            }
        }
    }
}

.header-panel {
    padding: 27px 0;
    margin-bottom: 20px;
    border-radius: 8px;

    .input-suffix,
    .disabledValueContainer {
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
    }
}

.helper_no-labels {
    label {
        display: none;
    }
}

.helper_inputs-like-text {

    .form-group{
        margin-bottom: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .col-form-label {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: $black;
        }
    }

    .form-control {
        @include input-like-text-field;
    }

    .formio-component-datetime{
      .input-group-append{
        display: none;
      }
    }
}

.formio-component-datetime{
  .input-group-append{
    display: none;
  }
}

.helper_input-like-text {
    .input-group {
        width: auto;
        .input-group-append {
            display: none;
        }
    }
    .form-control {
        @include input-like-text-field;
        flex: initial;
        width: auto;
    }
}

.helper_input-font-size-large {
    .form-control {
        font-size: 22px;
        font-weight: 600;
    }

    @media (max-width: 576px) {
        .form-control {
            font-size: 16px;
        }
    }
}

.helper_input-group-no-prefix {
    .input-group {
        .input-group-prepend {
            display: none;
        }
    }
}

.helper_input-group-no-suffix {
    .input-group {
        .input-group-append {
            display: none;
        }
    }
}

.campaign-selection-list {
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90px;
        border: 1px solid #dadee2 !important;
        border-radius: 8px;
        color: #000000 !important;
        text-decoration: none !important;
        font-weight: 700;
        font-size: 15px;
        line-height: 135%;
        margin-bottom: 20px;
        background: transparent !important;
        &:hover {
            border-color: var(--header-apply-btn-border-color) !important;
        }
    }
    .datagrid-table {
            thead {
                display: none !important;
            }

            tbody{
                tr:hover{
                    td{
                        background: transparent !important;
                    }
                }

                td{
                    border-bottom: none !important;
                    padding: 10px 0;
                }
            }
        }
}

.formio-component-loanRequest\.paymentFrequency {
    .form-radio {
        display: flex;
        flex-wrap: wrap;
        .form-check {
            margin-top: 0 !important;
            margin-left: 10px;
            flex: 1;
            margin-bottom: 7px;
        }
    }
}

.formio-component-termPeriodType {
        margin-bottom: 0 !important;
        .form-group {
            margin-bottom: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
}

.input-suffix {
    margin-left: 6px;
    display: flex;
    align-items: center;
}

.input-group {
    flex-wrap: nowrap;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-primary:focus, .btn-primary.focus {
    box-shadow: none !important;
}
.btn:focus, .btn.focus {
    box-shadow: none !important;
}

.formio-component-offers\.details {
    .btn-primary {
      white-space: nowrap;
    }
  }
