// Your variable overrides
$black: #0d0f1c;
$blue: #0a65ed;
$red: #f73743;
$tab-color: #a9afb7;
$label-color: #0D0F1C;

//body
$body-color: #0d0f1c;

$h1-font-size: 48px;

$border-radius: 8px;

//forms

$form-label-color: #828b94;
$label-margin-bottom: 4px;

$input-bg: white;
$input-disabled-bg: #f5f6f7;
$input-border-width: 1px;
$input-border-color: #dadee2;
$input-border-color-hover: #dadee2;
$input-focus-border-color: #84b5ff;
$input-font-size: 16px;
$input-line-height: 1.65;
$input-group-addon-bg: $input-bg;
$input-color: $black;
$input-height: 44px;

$input-check-label-color: #8b959e;

$form-check-input-gutter: 11px;

$form-group-margin-bottom: 20px;

$grid-gutter-width: 20px;

$card-border-width: 0;

$nav-tabs-border-width: 2px;
$nav-tabs-border-color: white;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-border-color: white white $blue;
$nav-tabs-link-active-color: $blue;

$card-spacer-x: 0;
$card-spacer-y: 10px;
$card-border-width: 0;
$card-border-radius: 0;
$card-border-color: transparent;
$card-cap-bg: white;

$btn-padding-x: 25px;

$table-border: 1px solid rgba(0, 0, 0, 0.1);

@import '~bootstrap/scss/bootstrap';

