
.formio-component-select {
    .formio-choices {
        &[data-type='select-multiple'] {
            .form-control {
                min-height: 44px;
            }
        }
        .form-control {
            padding-bottom: 0;
        }
    }
    .choices__item {
        line-height: 34px;
    }
    .choices__list {
        border: none !important;
        background: var(--background);
    }
    .choices__input {
        background: var(--input-bg) !important;
        border-color: var(--input-border-color) !important;
        &:hover {
            border-color: var(--input-border-color-hover) !important;
        }
    }
    .choices__item--selectable.is-highlighted {
        background-color: var(--input-bg) !important;
        opacity: 0.6 !important;
    }
    .choices__list--multiple {
        .choices__item {
            height: 30px;
            line-height: 25px;
            border-radius: 6px;
            background: #222325;
            border-color: #222325;
            padding-left: 15px;

            .choices__button {
                border-left-color: white;
            }
        }
    }
    .is-open {
        .choices__list--dropdown {
            border: 1px solid #dadee2;
            box-shadow: 0px 10px 30px rgba(27, 49, 82, 0.21);
        }
    }
}
