.formio-component-datagrid .datagrid-table, .formio-component-datagrid .datagrid-table td, .formio-component-datagrid .datagrid-table th {
    border: none !important;
    border-bottom: $table-border !important
}

.formio-component-datagrid {
    .datagrid-table {

       thead{
           tr{
               th{
                   border-bottom: $table-border;
               }
           }
       }


       tbody{
           tr:hover{
               td{
                   background: #F5F6F7;
               }
           }

           td{
               border-bottom: $table-border;
           }
       }

       th, td {
           border: none;
           font-size: 15px;
           line-height: 1.2;
           padding: 10px;
       }

       .form-control {
           font-size: 15px;
           @include input-like-text-field;
           border-radius: 0;
           line-height: 1.6;
       }


        .formio-component-datetime {
            .form-control[type='hidden'] + .form-control {
                border-radius: 0;
            }
        }

       a{ 
           padding: 3px 0;
           display: inline-block;
       }

   }
}
