header.ant-layout-header {
    box-shadow: none;
    position: static;

}

.ant-dropdown {
    .ant-dropdown-menu {
        background: #202429 !important;

        div {
            color: gray;
        }

        div:hover {
            color: #ffffff;
        }

        .icon-container {
            display: none;
        }
    }

    .ant-dropdown-arrow {
        display: none;
    }
}

.ant-menu-title-content {
    a[href="/process/DealProcess"] {
        display: none !important;
    }
}

.ant-menu {
    max-width: 900px;
}

.ant-menu-vertical {
    +div {
        +.ant-btn {
            display: none !important;

            +.ant-dropdown-trigger {
                div {
                    background: transparent;
                    border: 2px solid #bef6e6;

                    span {
                        svg {
                            fill: #bef6e6;
                        }
                    }
                }
            }
        }
    }
}

.header-panel {
    background: #EFF1FF12;
    padding: 20px 27px;

    label {
        color: #ffffff !important;
    }

    .row {
        justify-content: space-between;
        gap: 1rem;
    }
}

.formio-component-tabs {
    margin: 0 27px;

    .card-header-tabs {
        .nav-link {
            background: -webkit-linear-gradient(45deg, rgba(164, 252, 183, 1) 0%, rgba(73, 177, 235, 1) 64%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .nav-link:hover {
            background: -webkit-linear-gradient(45deg, rgba(223, 255, 230, 1) 0%, rgba(198, 234, 255, 1) 64%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .nav-link.active {
            background: -webkit-linear-gradient(45deg, rgba(223, 255, 230, 1) 0%, rgba(198, 234, 255, 1) 64%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.application-status_DECLINED {
    .disabledValueContainer {
        background: #A02020 !important;
        color: #ffffff !important;
    }
}

.red-panel {
    background: #A02020;
}

.form-check-label .form-check-input:checked+span {
    color: #8b959e;
}

.formio-slider-container {
    background: #EFF1FF12;

    .ant-input-number-input {
        color: #ffffff;
    }

    .ant-slider-track {
        background: linear-gradient(45deg, rgba(164, 252, 183, 1) 0%, rgba(73, 177, 235, 1) 70%);
    }
}

.ant-layout-header {
    display: flex;
    justify-content: center;

    svg {
        path {
            stroke: #ffffff;
        }
    }

    background: transparent !important;
}

.ant-collapse-content {
    background: linear-gradient(45deg, rgba(33, 45, 59, 1) 0%, rgba(10, 12, 14, 1) 46%, rgba(33, 45, 59, 1) 92%);
}

.ant-collapse-content-box {
    .ant-menu {
        background: transparent;

        .ant-menu-title-content {
            a {
                color: gray;
            }

            .active {
                color: #ffffff;
            }
        }
    }
}

main[selectorpagename="calculator"] {
    max-width: 700px;

    .datagrid-table {
        border-collapse: separate;
        border-spacing: 0 10px;
        padding: 0;

        thead {
            tr {
                th {
                    color: gray;
                    size: 21px;
                    font-weight: 500;
                }
            }
        }

        tbody {
            tr {
                td:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    padding-left: 25px !important;
                }

                td:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                td {
                    vertical-align: baseline;

                    div,
                    span {
                        font-size: 19px;
                    }

                    .btn {
                        width: 100%;
                    }
                }

                background: #EFF1FF12;
            }

            tr:hover {
                td {
                    background: #eff1ff35;
                }
            }
        }
    }
}

.header-panel {
    .input-group-prepend {
        .input-group-text {
            font-size: 22px;
            line-height: 22px;
        }
    }
}

.formio-component-datagrid .datagrid-table tbody tr:hover td {
    background: #EFF1FF12;
}

main[pagename="applications"],
main[pagename="loans"],
main[pagename="activity"] {
    max-width: 800px;
}

main[pagename="application"],
main[pagename="loan"] {
    max-width: 1100px;
}

main[pagename="process-DealProcess"] {
    max-width: 800px;
}

.formio-component-firstPayment {
    display: flex;
    gap: 15px;

    .field-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
    }
}

.file-remove {
    .fa-remove {
        color: #0b0c0e;
    }
}

@media (max-width: 760px) {

    header {
        .ant-dropdown-trigger {
            display: none !important;
        }
    }

}

@media (max-width: 576px) {

    .formio-component-tabs>.card>.card-header {
        display: block;
    }

    .formio-component-tabs>.card .tab-pane:not(.active) {
        display: none !important;
    }

    .card-header {
        .nav {
            flex-wrap: nowrap !important;
            overflow-x: scroll;
        }

        a.nav-link {
            white-space: nowrap !important;
        }
    }

    .ant-layout-content {
        width: 90% !important;
    }

    main[selectorpagename="calculator"] {
        .datagrid-table {
            thead {
                display: block;

                tr {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                }

                th {
                    display: block;
                    font-size: 15px;
                }

                th:last-child {
                    display: none;
                }
            }

            tbody {
                display: block;

                tr {
                    display: grid;
                    margin-bottom: 10px;

                    td:first-child {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }

                    td:last-child {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    border-radius: 10px;

                    td {
                        display: block;
                        span,
                        div {
                            font-size: 16px;
                        }
                    }

                    td:last-child {
                        grid-area: d;
                    }

                    grid-template-columns: repeat(4, 1fr);
                    grid-template-areas: ". . . ."
                    "d d d d";
                }
            }
        }



    }
}

main:has(div.formio-component-downPaymentOptions\.results) {
    h1 {
        text-align: center;
    }

    .formio-component-htmlelement {
        text-align: center;
    }
}


.btn-default {
    color: #ffffff;
}

.choices__list {
    background: #202429 !important;
}

.choices__list.choices__list--single {
    background: transparent !important;
}

.icon-container-active {
    display: none !important;
}

.file__footer {
    background: #EFF1FF12;
}

.files {
    margin-bottom: 10px;
}

.fa-cloud-upload {
    color: #0a65ed;
}

.campaign-selection-list {
    .btn-primary {
        color: #ffffff !important;
        background: #EFF1FF12 !important;
        border-color: #49b1eb !important;
    }

    .btn-primary:hover {
        border-color: #a4fcb7 !important;
    }
}

.choices[data-type*=select-one] .choices__button {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAAS9JREFUOE+VlbFxwzAMRT+KlNnAd9og2iALxCukyADuUqfxBD7X2SJu3EUb2NnA5w1SukAOMqBjIFIgWRKnd8Dn/xABADM/AvgCsCGis9y1HGZ+BrAFsCaiX0qAUpDTt4AV+K3fDiOYmeXCgFK7AnipATugDTcI9AnAyY0bghUokol06elINRXwAcAqqRbBBeCPanoZoS3gCCisCVoDrgHOoAH4A8DOaTiNnIr6r9NACnaTZYHZTgOwlYvARahK8Qbg03V401c+llKXHV+BEoicD8OAlDTNAaXDhxofz6ALtnlXKcKAeJ/mOpweRSMdJi9N1CKwJXmWfQHa+gptE3UsW6oJWOHjPrdPF42derPQ8bhP7Vdii7ojokvJ2P7e7eP75lejG/i1Beik2Ns/6g9xH8nstPFoAgAAAABJRU5ErkJggg==");
}

.choices[data-type*=select-one]:after {
    border-color: #919396 transparent transparent;
}

.iti__country-list {
    background: #202429;
    border: none;

    .iti__divider {
        display: none;
    }

    .iti__country-name {
        color: #ffffff;
    }

    .iti__country:hover {
        background: var(--input-bg);
    }
}

footer {
    background: transparent !important;
}

.formio-component-datetime {
    .input-group:has(> input.form-control) {
        .form-control {
            border-right: 1px solid var(--input-border-color);

            &:hover {
                border-right: 1px solid var(--input-border-color-hover);
            }

            &:focus,
            &:active {
                box-shadow: 0px 0px 4px rgba(10, 101, 237, 0.4);
                border-color: #84b5ff;
            }
        }
    }
}

.input-group-append {
    border-color: transparent !important;
    background: var(--input-bg);
    transition: all ease 0.2s;
}

.form-control:not(.is-invalid) {

    &:active,
    &:focus {

        &+.input-group-append {
            box-shadow: none !important;
            border-color: #1367E1 !important;
            border-left: none;
        }
    }

    &:hover {
        &+.input-group-append {
            border-color: var(--input-border-color-hover) !important;
            border-left: none
        }
    }
}

.form-control.is-invalid {
    &+.input-group-append {
        border-color: #f73743 !important;
    }
}

.btn:hover {
    color: #ffffff66;
}

.btn-primary {
    transition: all ease 0.2s;

    &:hover {
        opacity: 0.8;
    }
}

.ant-menu-item:hover a,
.ant-menu-item:hover {
    color: rgb(89, 88, 88) !important;
}

.disabledValueContainer {
    display: flex;
    align-items: center;
}

@media (max-width: 320px) {
    .disabledValueContainer {
        color: white;
        font-size: 9px;
    }

}

@media (max-width: 992px) {
    .header-panel {

        .input-suffix,
        .disabledValueContainer {
            font-size: 15px;
            line-height: 16px;
        }

        .helper_inputs-like-text {
            .col-form-label {
                font-size: 11px;
                word-wrap: initial;
            }
        }

        .input-group-prepend {
            .input-group-text {
                font-size: 15px;
                line-height: 16px;
            }
        }
    }

    .formio-component-form {
        h2 {
            font-size: 35px;
        }
    }

    .ant-layout-header {
        a {
            font-size: 19px;
        }
    }

    .input-as-status {
        .disabledValueContainer {
            font-size: 12px;
        }
    }

    .datagrid-table {
        th {
            font-size: 12px !important;
        }
    }

    td {

        .disabledValueContainer,
        .input-suffix {
            font-size: 12px;
        }


        .input-group-text {
            font-size: 12px;
        }
    }
}

.pagination-btn {
    background: transparent !important;
    color: #DCDCDC !important;

    img {
        display: none;
    }

    &:hover {
        background: #eff1ff33 !important;
    }
}

.pagination-btn.prev-btn,
.pagination-btn.next-btn,
.pagination-btn.active {
    position: relative;
    background: #EFF1FF12 !important;

    &:hover {
        background: #eff1ff33 !important;
    }
}

.pagination-btn.prev-btn::before {
    content: '<';
    color: #DCDCDC !important;
}

.pagination-btn.next-btn::after {
    content: '>';
    color: #DCDCDC !important;
}



.input-group:has(.form-control:not(.is-invalid):active, .form-control:not(.is-invalid):focus, .form-control:not(.is-invalid):hover) {
    .input-group-prepend {
        .input-group-text {
            transition: all ease 0.2s;
            border: 1px solid var(--input-border-color-hover) !important;
            border-right: none !important;
        }
    }
}

.input-group:has(.form-control:not(.is-invalid):active, .form-control:not(.is-invalid):focus) {
    .input-group-prepend {
        .input-group-text {
            transition: all ease 0.2s;
            border: 1px solid #1367E1 !important;
            border-right: none !important;
        }
    }
}

.input-group-text {
    transition: all ease 0.3s;
}

.form-control {
    transition: all ease 0.2s;
}

.input-group-prepend {
    transition: all ease 0.2s;

    .input-group-text {
        transition: all ease 0.2s;
    }
}

.input-group:has(.is-invalid) {
    .input-group-prepend {
        .input-group-text {
            transition: all ease 0.2s;
            border: 1px solid #f73743 !important;
            border-right: none !important;
        }
    }
}

.input-group-prepend {
    margin-right: 0 !important;
}

.form-control {
    transition: all ease 0.2s;
}

.input-group:has(.is-invalid:active) {
    .input-group-prepend {
        .input-group-text {
            border: 1px solid #f73743 !important;
            border-right: none !important;
        }
    }
}

.input-group:has(> .input-group-prepend) {
    .form-control {
        border-left: none !important;
    }
}

.input-group-prepend {
    border: none;

    .input-group-text {
        border: none;
        background: var(--input-bg);
        color: var(--label-color);
        border-right: none;
    }
}

.input-group-append {
    margin: 0;
}

.input-group-disabled {
    .input-group-append {
        background: transparent !important;
    }
    .input-group-text {
        background: transparent;
        padding: 0;
        margin-right: 6px;
        color: #ffffff;
    }
}

.form-control:not(.is-invalid) {
    border-right: 1px solid #EFF1FF12 !important;
}

.form-control:not(.is-invalid):active, input.form-control:not(.is-invalid):focus,  input.form-control:not(.is-invalid):hover {
    border-right: 1px solid #1367E1 !important;
}

.input-group:has(.input-group-append > .input-suffix) .form-control {
    border-right: none !important;
}

td {
    .formio-component:has(.disabledValueContainer) {
            .input-group {
                    flex-wrap: nowrap;
            }
    }
}

.dropdownContainer {
    background: #202429 !important;
    border-color: #202429 !important;

    border-radius: 8px;

    padding: 8px;

    box-shadow: 0 0.35rem 0.6rem rgba(0, 0, 0, 0.67) !important;

    z-index: 10;

    .btn {
            color: #DCDCDC !important;
            background: #202429 !important;
            height: auto;

            &:hover {
                    background: #44475775 !important;
            }
    }



    .formio-component:last-child {
        margin: 0;
    }
}

.formio-component-furtherInformation\.directDebitDecision + .formio-component-content {
    p {
        color: #f73743;
    }
}

