.form-control {

    +.input-group-append {
        transition: all ease 0.2s;
        display: flex;
        align-items: center;
        border: 1px solid #dadee2;
        border-radius: $border-radius;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-right: 1.3rem;

        .input-suffix {
            font-size: 16px;
            padding-left: 0.3rem;
            border-left: none;
            color: var(--label-color);
            line-height: 0;
        }
    }

}

.input-group:has(> input.form-control) {
    .form-control {
        border-right: none;
        padding-right: 0;
    }
}

.formio-component-datetime {
    .input-group:has(> input.form-control) {
        .form-control {
            border-right: 1px solid #dadee2;
            border-radius: 8px;
        }
    }

    .input-group:hover {
        .form-control {
            border-color: var(--input-border-color-hover) !important;
        }
    }
}


.form-control:not(.is-invalid):active,
.form-control:not(.is-invalid):focus,
.form-control:not(.is-invalid):hover {
    border-color: var(--input-border-color-hover) !important;
    &+.input-group-append {
        border-color: var(--input-border-color-hover) !important;
    }
}

.input-group:has(> .input-group-append) {
    .form-control {
        &.is-invalid {
            border-right: none !important;
    
            &+.input-group-append {
                border-color: $red;
                border-left: none;
            }
    
            &:focus {

                &+.input-group-append {
                    box-shadow: none;
                }
                box-shadow: none;
            }
        }
    }
}


.form-control:active, .form-control:focus:not(.is-invalid) {
    box-shadow: none;
    border-color: none;
}

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 0;
}
