.ant-btn {
    height: 44px;
    padding: 9px 12px;
    border-radius: 8px;
}
.ant-btn-primary {
    min-width: 147px;
    background-color: $blue;

    &:focus,
    &:hover {
        background-color: darken($blue, 10%);
    }
}
