.formio-component-form {
    h1{
        margin-bottom: 40px;
        color: var(--form-text-color);
    }
    h2 {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 48px;
        line-height: 105%;
        letter-spacing: -0.03em;
        color: var(--form-text-color);
    }

    @media (max-width: 576px) {
        h2 {
            font-size: 32px;
        }
    }

    iframe{
        border: none;
    }

    .btn-lg{
        height: 55px;
    }

    p.text-secondary{
        color: var(--calculator-secondary-text) !important;
    }
}
