.red-panel {
    padding: 11px 27px;
    background: #FFE4E4;
    border-radius: 8px;
    margin-bottom: 20px;
    .formio-component-declineReason {
        label {
            margin-right: 5px;
            font-size: 13px;
            line-height: 150%;
            color: #828b94;
        }
        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 150%;
        }
    }
    .col-md-6:nth-child(2) {
        display: flex;
        justify-content: flex-end;
        input {
            font-weight: 400;
            font-size: 13px;
            line-height: 150%;
        }
    }
}
