@import '~bootstrap/scss/mixins/_breakpoints.scss';

.formio-component-tabs {
    > .card {
        background: transparent;
        > .card-header {
            padding: 0;
            background-color: var(--background); 

            @media (max-width: 576px) {
                display: none;
            }
            
            .nav-tabs {
                border-bottom: 1px solid var(--tabs-border-bottom-color);
                margin: 0;

                .nav-link {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    color: var(--tab-color);
                    background-color: var(--background);
                    margin-bottom: -1px;
                    padding: 8px 0;
                    margin-right: 16px;
                    &.active {
                        color: var(--tabs-active-color);
                        background-color: var(--background);
                        border-bottom-color: var(--tabs-active-border-color);
                    }

                    &:not(.active):hover {
                        border-color: transparent;
                    }
                }
                // border-color: #dee2e6 #dee2e6 #fff;
            }
        }

        .tab-pane{

            @media (max-width: 576px) {
                display: block !important;
            }
        }
    }
}
